import { useState, useEffect, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  // getFleetListUser,
  relocalization,
} from "../../../apis";
import { FiNavigation2 } from "react-icons/fi";
import {
  getRobotDirectionbyMapbox,
  // robotmsgAction,
} from "../../../redux/actions";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import "mapbox-gl/dist/mapbox-gl.css";
import { showErrorToast, showSuccessToast } from "../../toast/toastType";

let origin;
let destination;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const checkActionId = (commandListData) => {
  if (
    !sessionStorage.getItem("actionId") ||
    sessionStorage.getItem("actionId") === "null"
  ) {
    sessionStorage.setItem(
      "actionId",
      JSON.stringify({
        actionType: commandListData.actionType,
        actionId: commandListData.actionId,
      })
    );
    return true;
  } else {
    if (
      commandListData.actionId !==
      !!Number(sessionStorage.getItem("actionId")).actionId
    ) {
      sessionStorage.setItem(
        "actionId",
        JSON.stringify({
          actionType: commandListData.actionType,
          actionId: commandListData.actionId,
        })
      );
      return true;
    } else {
      return false;
    }
  }
};

const isLatitude = (num) => isFinite(num) && Math.abs(num) <= 90;

const isLongitude = (num) => isFinite(num) && Math.abs(num) <= 180;

const isPointNearCircle = (
  pointLat,
  pointLng,
  centerLat,
  centerLng,
  radius
) => {
  var ky = 40000 / 360;
  var kx = Math.cos((Math.PI * centerLat) / 180.0) * ky;
  var dx = Math.abs(centerLng - pointLng) * kx;
  var dy = Math.abs(centerLat - pointLat) * ky;
  return Math.sqrt(dx * dx + dy * dy);
};

const MapboxMain = ({
  robotPathCheck,
  robotId,
  geofenceZone,
  nogoZones,
  fleetZones,
  robotLatitude,
  robotLongitude,
  width,
  slamPosition,
  setSlamPosition,
  difficultyLayerToggle,
  relocalisation,
}) => {
  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  // const { robotmsgData } = useSelector((state) => state.robotMessageReducer);
  const { robotDirections } = useSelector(
    (state) => state.robotDirectionsReducer
  );

  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(15);
  const [, setPolygonArray] = useState([]);
  const [robotMarker, setRobotMarker] = useState(null);
  const [destinationMarker, setDestinationMarker] = useState(null);
  const [userControllingMap, setUserControllingMap] = useState(false);
  const [navigationControlsRendered, setNavigationControlsRendered] =
    useState(false);
  const [relocationPointsData, setRelocationPointsData] = useState([]);

  const [nogoZonesCoordinates, setNogoZonesCoordinates] = useState(
    nogoZones.map((data) => [data.lng, data.lat])
  );
  const [geofenceCoordinates, setGeofenceCoordinates] = useState(
    geofenceZone.map((data) => [data.lng, data.lat])
  );
  
  const mapRef = useRef(null);
  let destinationMarkerForBound = [];

  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");

  window.relocalizeRobot = function (token, robotId, metricX, metricY, yaw) {
    // console.log("======= FOR TESTING PURPOSE LOGGING DATA ======");
    // console.log("======= NO API IS GETTING CALLED FOR RELOCALIZATION ======");
    // console.log("Metric X", metricX);
    // console.log("Metric Y", metricY);
    // console.log("Yaw", yaw);
    // console.log("Robot Id", robotId);
    relocalization(token, robotId, metricX, metricY, yaw)
      .then((res) => {
        // console.log("Open Door 2 Response", res)
        showSuccessToast("Robot relocalized successfully!");
      })
      .catch((err) => {
        //  console.log('Open Door 2 Error',err)
        showErrorToast("Something went wrong. Unable to relocalize!!");
      });
  };

  window.showRelocalizeImage = function (imageId) {
    const selectedImage = document.getElementById(
      "relocalization__image__no__" + imageId
    );
    const selectedSpan = document.getElementById(
      "relocalization__image__span__no__" + imageId
    );

    if (selectedImage.style.display === "none") {
      selectedImage.style.display = "block";
    } else {
      selectedImage.style.display = "none";
    }

    if (selectedSpan.innerText === "View Image") {
      selectedSpan.innerText = "Hide Image";
    } else {
      selectedSpan.innerText = "View Image";
    }
  };

  class PitchToggle {
    constructor({ bearing = -20, pitch = 70, minpitchzoom = null }) {
      this._bearing = bearing;
      this._pitch = pitch;
      this._minpitchzoom = minpitchzoom;
    }

    onAdd(map) {
      this._map = map;
      let _this = this;

      this._btn = document.createElement("button");
      this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
      this._btn.type = "button";
      this._btn["aria-label"] = "Toggle Pitch";
      this._btn.onclick = function () {
        if (map.getPitch() === 0) {
          let options = { pitch: _this._pitch };
          map.easeTo(options);
          _this._btn.className =
            "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
          setUserControllingMap(true);
          map.setLayoutProperty("building-extrusion", "visibility", "visible");
        } else {
          map.easeTo({ pitch: 0 });
          _this._btn.className =
            "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
          setUserControllingMap(true);
          map.setLayoutProperty("building-extrusion", "visibility", "none");
        }
      };

      this._container = document.createElement("div");
      this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
      this._container.appendChild(this._btn);

      return this._container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    }
  }

  const robotMarkerDiv = document.createElement("div");
  robotMarkerDiv.style.width = "35px";
  robotMarkerDiv.style.height = "35px";
  // robotMarkerDiv.style.backgroundImage =
  //   "url(/assets/images/robot-icon-pickup_1.svg)";
  robotMarkerDiv.style.borderRadius = "100%";
  robotMarkerDiv.style.animation = "example 1s ease-in-out infinite";

  const destinationMarkerDiv = document.createElement("div");
  destinationMarkerDiv.style.width = "35px";
  destinationMarkerDiv.style.height = "35px";
  // destinationMarkerDiv.style.visibility = "hidden";
  destinationMarkerDiv.style.backgroundImage =
    "url(/assets/images/dropoff.svg)";
  destinationMarkerDiv.style.borderRadius = "100%";
  // destinationMarkerDiv.style.animation = "example 1s ease-in-out infinite";

  const updateRobotPath = () => {
    const map = mapRef.current;
    if (!robotPathCheck) {
      if (
        robotDetails[robotId].hasOwnProperty("path") &&
        robotDetails[robotId].path.length > 0 &&
        robotDetails[robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[robotId].robotCommandList.length > 0 &&
        robotDetails[robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ) &&
        robotDetails[robotId].robotCommandList
          .find((x) => x.actionPriority === 1)
          .actionType.includes("MOVE")
        //   &&
        // map.isStyleLoaded()
      ) {
        const newrobotPath = robotDetails[robotId].path.map((pathDirection) => {
          if (!isLongitude(pathDirection.lng)) {
            showErrorToast("Receiving Invalid Longitude for robot path!");
            return [];
          }

          if (!isLatitude(pathDirection.lat)) {
            showErrorToast("Receiving Invalid Latitude for robot path!");
            return [];
          }

          return [pathDirection.lng, pathDirection.lat];
        });

        if (
          !newrobotPath.some(
            (element) => Array.isArray(element) && element.length === 0
          )
        ) {
          const mySource = map.getSource("robotPath");

          map &&
            mySource &&
            mySource.setData({
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: newrobotPath,
              },
            });

          if (
            !userControllingMap &&
            !!Number(process.env.REACT_APP_USER_MAP_CONTROL)
          ) {
            // Create a 'LngLatBounds' with both corners at the first coordinate.
            const bounds = new mapboxgl.LngLatBounds(
              newrobotPath[0],
              newrobotPath[0]
            );

            // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
            for (const coord of newrobotPath) {
              bounds.extend(coord);
            }

            bounds.extend([
              slamPosition
                ? parseFloat(robotDetails[robotId].longitudeSlam)
                : parseFloat(robotDetails[robotId].longitude),
              slamPosition
                ? parseFloat(robotDetails[robotId].latitudeSlam)
                : parseFloat(robotDetails[robotId].latitude),
            ]);

            if (destinationMarkerForBound.length !== 0) {
              bounds.extend(destinationMarkerForBound);
            }

            map.fitBounds(bounds, {
              padding: 100,
              bearing: robotDetails[robotId].headingSlam
                ? parseFloat(robotDetails[robotId].headingSlam)
                : 0,
            });
          }

          map.getLayer("path") &&
            map.setPaintProperty("path", "line-color", "#0f0");
        }
      } else {
        const robotPathSource = map.getSource("robotPath");
        map &&
          robotPathSource &&
          robotPathSource.setData({
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: [],
            },
          });
      }
    } else {
      if (
        robotDetails[robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[robotId].robotCommandList.length > 0 &&
        robotDetails[robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ) &&
        robotDetails[robotId].robotCommandList
          .find((x) => x.actionPriority === 1)
          .actionType.includes("MOVE") &&
        // map.isStyleLoaded() &&
        checkActionId(
          robotDetails[robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          )
        )
      ) {
        destination = {
          lat: robotDetails[robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          ).latitude,
          lng: robotDetails[robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          ).longitude,
        };
        origin = {
          lat: robotLatitude,
          lng: robotLongitude,
        };

        dispatch(getRobotDirectionbyMapbox(origin, destination));
      }

      if (robotDirections && robotDirections.hasOwnProperty("routes")) {
        const mapboxPath = robotDirections.routes[0].geometry.coordinates;
        let tempMapBoxPath = [...mapboxPath];
        let nearest = 0;
        tempMapBoxPath.forEach((path, index) => {
          if (
            isPointNearCircle(
              path[1],
              path[0],
              parseFloat(robotDetails[robotId].latitude),
              parseFloat(robotDetails[robotId].longitude),
              0.01
            ) <
            isPointNearCircle(
              tempMapBoxPath[nearest][1],
              tempMapBoxPath[nearest][0],
              parseFloat(robotDetails[robotId].latitude),
              parseFloat(robotDetails[robotId].longitude),
              0.01
            )
          ) {
            nearest = index;
          }
        });

        tempMapBoxPath.slice(nearest);

        const mySource = map.getSource("robotPath");

        map &&
          mySource &&
          mySource.setData({
            type: "FeatureCollection",
            name: "robotPaths",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: tempMapBoxPath,
                },
              },
              {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [
                    [
                      tempMapBoxPath.length && tempMapBoxPath[0][0],
                      tempMapBoxPath.length && tempMapBoxPath[0][1],
                    ],
                    [
                      slamPosition
                        ? robotDetails[robotId]?.longitudeSlam
                        : parseFloat(robotDetails[robotId]?.longitude),
                      slamPosition
                        ? robotDetails[robotId]?.latitudeSlam
                        : parseFloat(robotDetails[robotId]?.latitude),
                    ],
                  ],
                },
              },
            ],
          });
        map.getLayer("path") &&
          map.setPaintProperty("path", "line-color", "#A020F0");

        if (
          !userControllingMap &&
          !!Number(process.env.REACT_APP_USER_MAP_CONTROL)
        ) {
          // Create a 'LngLatBounds' with both corners at the first coordinate.
          const bounds = new mapboxgl.LngLatBounds(
            tempMapBoxPath[0],
            tempMapBoxPath[0]
          );

          // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
          for (const coord of tempMapBoxPath) {
            bounds.extend(coord);
          }

          bounds.extend([
            slamPosition
              ? parseFloat(robotDetails[robotId].longitudeSlam)
              : parseFloat(robotDetails[robotId].longitude),
            slamPosition
              ? parseFloat(robotDetails[robotId].latitudeSlam)
              : parseFloat(robotDetails[robotId].latitude),
          ]);

          map.fitBounds(bounds, {
            padding: 100,
            bearing: robotDetails[robotId].headingSlam
              ? parseFloat(robotDetails[robotId].headingSlam)
              : 0,
          });
        }
      }
    }
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/assets/dataFiles/relocalization_points_with_id.csv`
      )
      .then((response) => {
        const lines = response.data.split("\n");

        if (lines.length > 1) {
          const headers = lines[0].split(",");

          const jsonResult = lines.slice(1).map((line) => {
            const values = line.split(",");
            return headers.reduce((obj, header, index) => {
              obj[header] = values[index];
              return obj;
            }, {});
          });

          setRelocationPointsData(jsonResult);
          // console.log(relocationPointsData, "RELOCALISATION");
        }
      })
      .catch((error) => {
        console.error("Error fetching CSV:", error);
      });
  }, []);

  useEffect(() => {
    if (!navigationControlsRendered) return;

    const navigationControls = document.querySelectorAll(
      ".mapboxgl-ctrl-group"
    );

    const handleNavigationButtonClick = (e) => {
      setUserControllingMap(true);
    };

    navigationControls.length > 0 &&
      navigationControls.forEach((control) => {
        control.addEventListener("mousedown", handleNavigationButtonClick);
        return () => {
          control.removeEventListener("mousedown", handleNavigationButtonClick);
        };
      });
  }, [navigationControlsRendered]);

  useEffect(() => {
    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        container: "map-container-id",
        style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
        center: [
          slamPosition
            ? robotDetails[robotId].longitudeSlam
            : robotDetails[robotId].longitude,
          slamPosition
            ? robotDetails[robotId].latitudeSlam
            : robotDetails[robotId].latitude,
        ],
        zoom: zoom,
      });

      newMap.on("style.load", () => {
        newMap.setLayoutProperty("building-extrusion", "visibility", "none");
      });

      robotDetails[robotId].longitudeSlam
        ? setSlamPosition(true)
        : setSlamPosition(false);

      // Marker type for with/without heading
      robotMarkerDiv.style.backgroundImage =
        robotDetails[robotId].headingSlam &&
        !!Number(process.env.REACT_APP_HEADING)
          ? "url(/assets/images/robot-icon-2.svg)"
          : "url(/assets/images/robot-icon-pickup_1.svg)";

      // Initialize robot marker
      let newRobotMarker;

      if (
        robotDetails[robotId].headingSlam &&
        !!Number(process.env.REACT_APP_HEADING)
      ) {
        newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
          .setLngLat([
            slamPosition
              ? robotDetails[robotId].longitudeSlam
              : robotDetails[robotId].longitude,
            slamPosition
              ? robotDetails[robotId].latitudeSlam
              : robotDetails[robotId].latitude,
          ])
          .setRotation(parseFloat(robotDetails[robotId].headingSlam))
          .setRotationAlignment("map")
          .addTo(newMap);
      } else {
        newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
          .setLngLat([
            slamPosition
              ? robotDetails[robotId].longitudeSlam
              : robotDetails[robotId].longitude,
            slamPosition
              ? robotDetails[robotId].latitudeSlam
              : robotDetails[robotId].latitude,
          ])
          .addTo(newMap);
      }

      // Initialize destination marker
      const destinationMarker = new mapboxgl.Marker(destinationMarkerDiv)
        .setLngLat([0, 0])
        .addTo(newMap);

      setDestinationMarker(destinationMarker);
      destinationMarkerForBound = [];

      newMap.on("load", () => {
        newMap.addSource("nogozone", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [nogoZonesCoordinates],
            },
          },
        });

        newMap.addLayer({
          id: "nogozoneoutline",
          type: "line",
          source: "nogozone",
          layout: {},
          paint: {
            "line-color": "#f00",
            "line-width": 3,
          },
        });
      });

      newMap.on("load", () => {
        newMap.addSource("geofence", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",

              coordinates: [geofenceCoordinates],
            },
          },
        });

        newMap.addLayer({
          id: "geofenceoutline",
          type: "line",
          source: "geofence",
          layout: {},
          paint: {
            "line-color": "#f00",
            "line-width": 3,
          },
        });
      });

      if (process.env.REACT_APP_BASE_URL.includes("neom")) {
        newMap.on("load", () => {
          newMap.addSource("neom-map", {
            type: "image",
            url: `${process.env.PUBLIC_URL}/assets/images/neom-googlemap.png`,
            coordinates: [
              [35.53023738, 27.55236906],
              [35.53594313, 27.55185625],
              [35.53518996, 27.54520011],
              [35.52948455, 27.54571287],
            ],
          });
          newMap.addLayer({
            id: "radar-layer",
            type: "raster",
            source: "neom-map",
            paint: {
              "raster-fade-duration": 0,
            },
          });
        });
      }

      if (process.env.REACT_APP_BASE_URL.includes("chick")) {
        newMap.on("load", () => {
          const layers = newMap.getStyle().layers;

          // Find the index of the first symbol layer in the map style.
          let firstSymbolId;
          for (const layer of layers) {
            if (layer.type === "symbol") {
              firstSymbolId = layer.id;
              break;
            }
          }
          newMap.addSource("chickfila-map", {
            type: "image",
            url: `${process.env.PUBLIC_URL}/assets/images/chickfila-googlemap.png`,
            coordinates: [
              [-95.5451623748, 29.784804206],
              [-95.5346301602, 29.7849896156],
              [-95.5344598025, 29.7777023516],
              [-95.5449909197, 29.7775141246],
            ],
          });
          newMap.addLayer(
            {
              id: "chickfila-radar-layer",
              type: "raster",
              source: "chickfila-map",
              paint: {
                "raster-fade-duration": 0,
                "raster-opacity": 0.5,
              },
            },
            firstSymbolId
          );

          // newMap.addSource("chickfila-ground-map", {
          //   type: "image",
          //   url: `${process.env.PUBLIC_URL}/assets/images/chickfila-ground-map.png`,
          //   coordinates: [
          //     [-95.5451623748, 29.784804206],
          //     [-95.5346301602, 29.7849896156],
          //     [-95.5344598025, 29.7777023516],
          //     [-95.5449909197, 29.7775141246],
          //   ],
          // });
          // newMap.addLayer(
          //   {
          //     id: "chickfila-ground-radar-layer",
          //     type: "raster",
          //     source: "chickfila-ground-map",
          //     paint: {
          //       "raster-fade-duration": 0,
          //       // "raster-opacity": 0.5,
          //     },
          //   },
          //   firstSymbolId
          // );
        });
      }

      newMap.on("wheel", () => {
        setUserControllingMap(true);
      });

      newMap.on("drag", () => {
        setUserControllingMap(true);
      });

      let polygonArray = [];
      let offset = 0.00003;

      fleetZones.map((polygon, index) => {
        polygonArray.push(polygon.map((data) => [data.lng, data.lat + offset]));

        newMap.on("load", () => {
          newMap.addSource("fleetzone-" + index, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",

                coordinates: [polygonArray[index]],
              },
            },
          });

          newMap.addLayer({
            id: "fleetzoneoutline-" + index,
            type: "fill",
            source: "fleetzone-" + index,
            layout: {},
            paint: {
              "fill-color": "#7859bc",
              "fill-opacity": 0.75,
            },
          });
        });

        return polygonArray;
      });

      // Add zoom and rotation controls to the map.
      newMap.addControl(
        new mapboxgl.NavigationControl({
          showCompass: true,
          showZoom: true,
          visualizePitch: true,
        })
      );

      setNavigationControlsRendered(true);

      newMap.addControl(new PitchToggle({ minpitchzoom: 11 }), "top-right");

      newMap.on("move", () => {
        setZoom(newMap.getZoom().toFixed(2));
      });

      newMap.on("idle", function () {
        newMap.resize();
      });

      setPolygonArray(polygonArray);
      setRobotMarker(newRobotMarker);
      setMap(newMap);
      mapRef.current = newMap;
    };

    if (!map) {
      initializeMap();
    }
    return () => {};
  }, [map, nogoZones, fleetZones, geofenceZone, setMap]);

  useEffect(() => {
    // if (
    //   (robotmsgData &&
    //     robotmsgData.length > 0 &&
    //     !robotmsgData.find((robotData) => robotData.location === "zaragoza")) ||
    //   !robotmsgData
    // ) {
    //   getFleetListUser(
    //     sessionStorage.getItem("useremail"),
    //     sessionStorage.getItem("token")
    //   ).then((res) => {
    //     dispatch(robotmsgAction(res.data.fleet));
    //   });
    // }

    if (map && map.isStyleLoaded()) {
      // if (
      //   robotmsgData &&
      //   robotmsgData.find(
      //     (robotData) => robotData.fleetName === "Peoria_Riverfront"
      //   )
      // )
      if (process.env.REACT_APP_BASE_URL.includes("hammerhead")) {
        !map.hasImage("custom-marker") &&
          map.loadImage(
            "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
            (error, image) => {
              if (error) throw error;
              map.addImage("custom-marker", image);
            }
          );

        // Add a GeoJSON source with 2 points
        !map.getSource("riverFront") &&
          map.addSource("riverFront", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              name: "riverFronts",
              features: [
                {
                  type: "Feature",
                  properties: { Name: "Point 1" },
                  geometry: {
                    type: "Point",
                    coordinates: [-89.591121, 40.68748],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Point 2" },
                  geometry: {
                    type: "Point",
                    coordinates: [-89.588448, 40.688114],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Point 3" },
                  geometry: {
                    type: "Point",
                    coordinates: [-89.587556, 40.688682],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Point 4" },
                  geometry: {
                    type: "Point",
                    coordinates: [-89.586918, 40.689669],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Point 5" },
                  geometry: {
                    type: "Point",
                    coordinates: [-89.589788, 40.688297],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 1" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5924303, 40.6866848],
                        [-89.5923981, 40.6866523],
                        [-89.5911804, 40.6874068],
                        [-89.5912179, 40.6874475],
                        [-89.5924303, 40.6866848],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 2" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5912179, 40.6874475],
                        [-89.5912558, 40.6874303],
                        [-89.5912102, 40.6873958],
                        [-89.5900327, 40.6881117],
                        [-89.5900703, 40.6881503],
                        [-89.5912179, 40.6874475],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 3" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5900969, 40.6881302],
                        [-89.5899896, 40.6880204],
                        [-89.5899668, 40.6880072],
                        [-89.589948, 40.6879899],
                        [-89.5896838, 40.6877347],
                        [-89.5896731, 40.6877133],
                        [-89.5896597, 40.6876838],
                        [-89.5896355, 40.6876594],
                        [-89.5896262, 40.6876442],
                        [-89.5895819, 40.6876584],
                        [-89.5896114, 40.6876787],
                        [-89.5896235, 40.687693],
                        [-89.5896195, 40.6877123],
                        [-89.5896141, 40.6877235],
                        [-89.5900703, 40.6881503],
                        [-89.5900969, 40.6881302],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 5" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5895815, 40.6874292],
                        [-89.5895567, 40.6874078],
                        [-89.5891593, 40.6876462],
                        [-89.5889125, 40.6877967],
                        [-89.588529, 40.6880347],
                        [-89.5882715, 40.6881831],
                        [-89.5879094, 40.6884211],
                        [-89.5876519, 40.6885757],
                        [-89.5873676, 40.6887506],
                        [-89.5871718, 40.6888604],
                        [-89.5867775, 40.6891106],
                        [-89.5868312, 40.6891553],
                        [-89.5870216, 40.6890394],
                        [-89.5872442, 40.6889051],
                        [-89.5875178, 40.6887445],
                        [-89.5877136, 40.6886082],
                        [-89.5879684, 40.6884597],
                        [-89.588191, 40.6883092],
                        [-89.5884968, 40.6881181],
                        [-89.5885183, 40.6880937],
                        [-89.5885639, 40.6880652],
                        [-89.5886041, 40.6880347],
                        [-89.5895815, 40.6874292],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 6" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.587067, 40.6893182],
                        [-89.5869892, 40.6892348],
                        [-89.587008, 40.6890538],
                        [-89.586953, 40.6890029],
                        [-89.5867827, 40.6891168],
                        [-89.5870147, 40.6893487],
                        [-89.587067, 40.6893182],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 10" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5900618, 40.6880981],
                        [-89.5898727, 40.6882253],
                        [-89.5899009, 40.6882507],
                        [-89.5900969, 40.6881302],
                        [-89.5900618, 40.6880981],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 11" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5898969, 40.6882131],
                        [-89.5897628, 40.6882924],
                        [-89.5897721, 40.6883066],
                        [-89.5896233, 40.6883961],
                        [-89.5896125, 40.688388],
                        [-89.5895401, 40.6884368],
                        [-89.5894248, 40.6884978],
                        [-89.5892437, 40.6886117],
                        [-89.5890788, 40.6887134],
                        [-89.5889849, 40.6887673],
                        [-89.5889004, 40.6888212],
                        [-89.5887811, 40.6888883],
                        [-89.5888092, 40.6889127],
                        [-89.5889058, 40.6888598],
                        [-89.5890386, 40.6887774],
                        [-89.5891351, 40.6887144],
                        [-89.5892223, 40.6886625],
                        [-89.5893255, 40.6886076],
                        [-89.5894275, 40.6885375],
                        [-89.5895321, 40.6884724],
                        [-89.5897641, 40.6883442],
                        [-89.5898633, 40.6882731],
                        [-89.589921, 40.6882364],
                        [-89.5898969, 40.6882131],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 12" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5888323, 40.6889005],
                        [-89.5888068, 40.6888746],
                        [-89.5886392, 40.6889738],
                        [-89.5886713, 40.6889982],
                        [-89.5887424, 40.688956],
                        [-89.5888323, 40.6889005],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 13" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5886498, 40.6889684],
                        [-89.5885157, 40.6890457],
                        [-89.5884245, 40.6891037],
                        [-89.5883199, 40.6891637],
                        [-89.5882206, 40.6892308],
                        [-89.5881066, 40.689304],
                        [-89.587947, 40.6893975],
                        [-89.5877901, 40.6894931],
                        [-89.5877164, 40.6895399],
                        [-89.5875903, 40.6896172],
                        [-89.5874763, 40.6896894],
                        [-89.5875031, 40.6897118],
                        [-89.5876225, 40.6896396],
                        [-89.5876976, 40.6895887],
                        [-89.5878169, 40.6895186],
                        [-89.5879108, 40.6894616],
                        [-89.5880114, 40.6893996],
                        [-89.5880865, 40.6893548],
                        [-89.5882233, 40.6892704],
                        [-89.5883816, 40.6891799],
                        [-89.5885116, 40.6891006],
                        [-89.5886779, 40.6889918],
                        [-89.5886498, 40.6889684],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 14" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5875176, 40.6896992],
                        [-89.5874935, 40.6896788],
                        [-89.5872091, 40.6898517],
                        [-89.5872386, 40.6898731],
                        [-89.5875176, 40.6896992],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 12" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.587092, 40.6893405],
                        [-89.5869096, 40.6891188],
                        [-89.5866173, 40.6892775],
                        [-89.5866012, 40.6893649],
                        [-89.586742, 40.6895347],
                        [-89.5869981, 40.6898052],
                        [-89.5871436, 40.6898149],
                        [-89.5872261, 40.6898835],
                        [-89.5873327, 40.6898205],
                        [-89.5872932, 40.6897818],
                        [-89.5872301, 40.6897727],
                        [-89.587033, 40.6897518],
                        [-89.5868144, 40.6895103],
                        [-89.587092, 40.6893405],
                      ],
                    ],
                  },
                },
                {
                  type: "Feature",
                  properties: { Name: "Polygon 13" },
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-89.5896355, 40.6876594],
                        [-89.5895685, 40.6875826],
                        [-89.5895564, 40.6875791],
                        [-89.589537, 40.6875618],
                        [-89.5895591, 40.6875323],
                        [-89.5895745, 40.6874962],
                        [-89.5895506, 40.6874337],
                        [-89.5895237, 40.6874398],
                        [-89.5895103, 40.6874632],
                        [-89.5895398, 40.6874805],
                        [-89.5895323, 40.6875099],
                        [-89.5895142, 40.6875308],
                        [-89.5895021, 40.6875694],
                        [-89.5895155, 40.6875882],
                        [-89.5895229, 40.6876086],
                        [-89.589596, 40.6876675],
                        [-89.5896355, 40.6876594],
                      ],
                    ],
                  },
                },
              ],
            },
          });

        !map.getLayer("RiverfrontLineLayer") &&
          map.addLayer({
            id: "RiverfrontLineLayer",
            type: "fill",
            source: "riverFront",
            paint: {
              "fill-color": "#7859bc",
              "fill-opacity": 0.75,
            },
            filter: ["==", "$type", "Polygon"],
          });

        !map.getLayer("Riverfront") &&
          map.addLayer({
            id: "Riverfront",
            type: "symbol",
            source: "riverFront",
            layout: {
              "icon-image": "custom-marker",
              "icon-size": 0.65,
            },
            filter: ["==", "$type", "Point"],
          });
      }

      // if (
      //   robotmsgData &&
      //   robotmsgData.find((robotData) => robotData.location === "zaragoza")
      // )
      if (process.env.REACT_APP_BASE_URL.includes("goggo")) {
        !map.getSource("zaragoza-paths-source") &&
          map.addSource("zaragoza-paths-source", {
            type: "vector",
            url: "mapbox://mrlakshyagupta.cllfacxj133q62bkx2inscfxs-9un25",
          });

        !map.getLayer("zaragoza-paths-layer") &&
          map.addLayer({
            id: "zaragoza-paths-layer",
            type: "line",
            source: "zaragoza-paths-source",
            "source-layer": "zaragoza-paths",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#4B0082",
              "line-width": 2,
            },
          });

        !map.getSource("zaragoza-okoutline-source") &&
          map.addSource("zaragoza-okoutline-source", {
            type: "vector",
            url: "mapbox://mrlakshyagupta.cliockddp17ir2onppxm67ul4-2322c",
          });

        !map.getLayer("zaragoza-okoutline") &&
          map.addLayer({
            id: "zaragoza-okoutline",
            type: "line",
            source: "zaragoza-okoutline-source",
            "source-layer": "Zaragoza-OK",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#0000ff",
              "line-width": 2,
            },
          });

        !map.getSource("zaragoza-goodoutline-source") &&
          map.addSource("zaragoza-goodoutline-source", {
            type: "vector",
            url: "mapbox://mrlakshyagupta.cliockw3y0x522ophzjv42pw5-05s1c",
          });

        !map.getLayer("zaragoza-goodoutline") &&
          map.addLayer({
            id: "zaragoza-goodoutline",
            type: "line",
            source: "zaragoza-goodoutline-source",
            "source-layer": "Zaragoza-GOOD",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#027148",
              "line-width": 2,
            },
          });

        !map.getSource("zaragoza-difficultoutline-source") &&
          map.addSource("zaragoza-difficultoutline-source", {
            type: "vector",
            url: "mapbox://mrlakshyagupta.cliocljvn0yvs2cphplde8mwp-65qz7",
          });

        !map.getLayer("zaragoza-difficultoutline") &&
          map.addLayer({
            id: "zaragoza-difficultoutline",
            type: "line",
            source: "zaragoza-difficultoutline-source",
            "source-layer": "Zaragoza-DIFFICULT",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#8B0000",
              "line-width": 2,
            },
          });

        !map.getSource("zaragoza-nooutline-source") &&
          map.addSource("zaragoza-nooutline-source", {
            type: "vector",
            url: "mapbox://mrlakshyagupta.cliocqzf906lb2bo9xa6o69r5-5ubuf",
          });

        !map.getLayer("zaragoza-nooutline") &&
          map.addLayer({
            id: "zaragoza-nooutline",
            type: "line",
            source: "zaragoza-nooutline-source",
            "source-layer": "Zaragoza-NO",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#FF0000",
              "line-width": 2,
            },
          });

        map.getLayer("zaragoza-paths-layer") &&
          map.setLayoutProperty(
            "zaragoza-paths-layer",
            "visibility",
            "visible"
          );

        map.getLayer("zaragoza-okoutline") &&
          map.setLayoutProperty("zaragoza-okoutline", "visibility", "none");

        map.getLayer("zaragoza-goodoutline") &&
          map.setLayoutProperty("zaragoza-goodoutline", "visibility", "none");

        map.getLayer("zaragoza-difficultoutline") &&
          map.setLayoutProperty(
            "zaragoza-difficultoutline",
            "visibility",
            "none"
          );
        map.getLayer("zaragoza-nooutline") &&
          map.setLayoutProperty("zaragoza-nooutline", "visibility", "none");
        if (
          !!Number(process.env.REACT_APP_DIFFICULTY_LAYER) &&
          !difficultyLayerToggle
        ) {
          map.getLayer("zaragoza-paths-layer") &&
            map.setLayoutProperty(
              "zaragoza-paths-layer",
              "visibility",
              "visible"
            );

          map.getLayer("zaragoza-okoutline") &&
            map.setLayoutProperty("zaragoza-okoutline", "visibility", "none");

          map.getLayer("zaragoza-goodoutline") &&
            map.setLayoutProperty("zaragoza-goodoutline", "visibility", "none");

          map.getLayer("zaragoza-difficultoutline") &&
            map.setLayoutProperty(
              "zaragoza-difficultoutline",
              "visibility",
              "none"
            );
          map.getLayer("zaragoza-nooutline") &&
            map.setLayoutProperty("zaragoza-nooutline", "visibility", "none");
        } else if (
          !!Number(process.env.REACT_APP_DIFFICULTY_LAYER) &&
          difficultyLayerToggle
        ) {
          map.getLayer("zaragoza-paths-layer") &&
            map.setLayoutProperty("zaragoza-paths-layer", "visibility", "none");

          map.getLayer("zaragoza-okoutline") &&
            map.setLayoutProperty(
              "zaragoza-okoutline",
              "visibility",
              "visible"
            );

          map.getLayer("zaragoza-goodoutline") &&
            map.setLayoutProperty(
              "zaragoza-goodoutline",
              "visibility",
              "visible"
            );
          map.getLayer("zaragoza-difficultoutline") &&
            map.setLayoutProperty(
              "zaragoza-difficultoutline",
              "visibility",
              "visible"
            );
          map.getLayer("zaragoza-nooutline") &&
            map.setLayoutProperty(
              "zaragoza-nooutline",
              "visibility",
              "visible"
            );
        }
      }

      if (
        !map.getSource("robotPath") &&
        ((robotDetails[robotId].hasOwnProperty("path") &&
          robotDetails[robotId].path.length > 0) ||
          (robotDetails[robotId].hasOwnProperty("robotCommandList") &&
            robotDetails[robotId].robotCommandList.length > 0 &&
            robotDetails[robotId].robotCommandList.find(
              (x) => x.actionPriority === 1
            ) &&
            robotDetails[robotId].robotCommandList
              .find((x) => x.actionPriority === 1)
              .actionType.includes("MOVE")))
      ) {
        let newrobotPath = [];
        newrobotPath =
          robotDetails[robotId].path &&
          robotDetails[robotId].path.map((pathDirection) => [
            pathDirection.lng,
            pathDirection.lat,
          ]);

        map.addSource("robotPath", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: newrobotPath,
            },
          },
        });

        map.addLayer({
          id: "path",
          type: "line",
          source: "robotPath",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#0f0",
            "line-width": 5,
          },
        });
      }

      // let chickfilapath = [
      //   // [-95.5344598025, 29.7777023516],
      //   // [-95.5346301602, 29.7849896156],
      //   // [-95.5451623748, 29.784804206],
      //   // [-95.5449909197, 29.7775141246],
      //   [-95.5451623748, 29.784804206],
      //   [-95.5346301602, 29.7849896156],
      //   [-95.5344598025, 29.7777023516],
      //   [-95.5449909197, 29.7775141246],
      // ];

      // // Create a 'LngLatBounds' with both corners at the first coordinate.
      // const bounds = new mapboxgl.LngLatBounds(
      //   chickfilapath[0],
      //   chickfilapath[0]
      // );

      // // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
      // for (const coord of chickfilapath) {
      //   bounds.extend(coord);
      // }

      // map.fitBounds(bounds, {
      //   padding: 20,
      // });

      if (
        !userControllingMap &&
        !!Number(process.env.REACT_APP_USER_MAP_CONTROL) &&
        robotDetails[robotId].hasOwnProperty("path") &&
        robotDetails[robotId].path.length > 0 &&
        robotDetails[robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[robotId].robotCommandList.length > 0 &&
        robotDetails[robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ) &&
        robotDetails[robotId].robotCommandList
          .find((x) => x.actionPriority === 1)
          ?.actionType.includes("MOVE")
      ) {
        let newrobotPath = [];
        newrobotPath =
          robotDetails[robotId].path &&
          robotDetails[robotId].path.map((pathDirection) => {
            if (!isLongitude(pathDirection.lng)) {
              showErrorToast("Receiving Invalid Longitude for robot path!");
              return [];
            }

            if (!isLatitude(pathDirection.lat)) {
              showErrorToast("Receiving Invalid Latitude for robot path!");
              return [];
            }

            return [pathDirection.lng, pathDirection.lat];
          });

        if (
          !newrobotPath.some(
            (element) => Array.isArray(element) && element.length === 0
          )
        ) {
          // Create a 'LngLatBounds' with both corners at the first coordinate.
          const bounds = new mapboxgl.LngLatBounds(
            newrobotPath[0],
            newrobotPath[0]
          );

          // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
          for (const coord of newrobotPath) {
            bounds.extend(coord);
          }

          map.fitBounds(bounds, {
            padding: 20,
          });
        }
      } else if (
        !userControllingMap &&
        !!Number(process.env.REACT_APP_USER_MAP_CONTROL) &&
        nogoZonesCoordinates.length > 0
      ) {
        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          nogoZonesCoordinates[0],
          nogoZonesCoordinates[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of nogoZonesCoordinates) {
          bounds.extend(coord);
        }

        map.fitBounds(bounds, {
          padding: 20,
        });
      } else if (
        !userControllingMap &&
        !!Number(process.env.REACT_APP_USER_MAP_CONTROL) &&
        geofenceCoordinates.length > 0
      ) {
        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          geofenceCoordinates[0],
          geofenceCoordinates[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of geofenceCoordinates) {
          bounds.extend(coord);
        }

        map.fitBounds(bounds, {
          padding: 20,
        });
      }

      if (!!Number(process.env.REACT_APP_RELOCALISATION)) {
        !map.hasImage("relocalisation-marker") &&
          map.loadImage(
            `${process.env.PUBLIC_URL}/assets/images/robot-icon-5.png`,
            (error, image) => {
              if (error) throw error;
              map.addImage("relocalisation-marker", image);
            }
          );

        !map.getSource("relocationPoints-0") &&
          relocationPointsData.map((data, index) => {
            map.addSource(`relocationPoints-${index}`, {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                name: `Relocation-Point-${index}`,
                features: [
                  {
                    type: "Feature",
                    properties: {
                      description: `
                      <div><strong>Name: </strong><span>${data.Name}</span></div>
                      <div><strong>Map X: </strong><span>${data.Map_x}</span></div>
                      <div><strong>Map Y: </strong><span>${data.Map_y}</span></div>
                      <div><strong>Map Yaw: </strong><span>${data.Map_yaw}</span></div>
                      <div><strong>Latitude: </strong><span>${data.Lat}</span></div>
                      <div><strong>Longitude: </strong><span>${data.Lon}</span></div>
                      <div><strong>Heading: </strong><span>${data.Heading_angle}</span></div>
                      <div class="w-full flex justify-between items-center">
                      <span id="relocalization__image__span__no__${index}" class="border-b border-[#d04522] cursor-pointer font-poppins text-[11px] text-[#d04522]" onclick="showRelocalizeImage('${index}')">View Image</span>
                      <button class="rounded-[4px] px-2 py-0.5 bg-[#d04522] text-white font-poppins text-[11px]" onclick="relocalizeRobot('${token}','${robotId}','${data.Map_x}', '${data.Map_y}', '${data.Map_yaw}')">Relocalize</button>
                      </div>
                      <a></a>
                      <img id="relocalization__image__no__${index}" style="display:none;" class="mt-2 w-full h-auto" src="https://imagedelivery.net/HOqw_0sYZTUgFQ-soBOgtg/${data.Image_id}/public" alt="relocalization__point__image" />
                     `,
                    },
                    geometry: {
                      type: "Point",
                      coordinates: [data.Lon, data.Lat],
                    },
                  },
                ],
              },
            });

            return null;
          });

        !map.getLayer("relocationPointsLayer-0") &&
          relocationPointsData.map((data, index) => {
            map.addLayer({
              id: `relocationPointsLayer-${index}`,
              type: "symbol",
              source: `relocationPoints-${index}`,
              layout: {
                "icon-image": "relocalisation-marker",
                "icon-size": 0.1,
                "icon-allow-overlap": true,
                "icon-rotate": parseFloat(data.Heading_angle),
                "icon-rotation-alignment": "map",
                "text-allow-overlap": true,
                "text-ignore-placement": true,
                "icon-ignore-placement": true,
              },
              paint: {
                "icon-opacity": 1,
              },
              filter: ["==", "$type", "Point"],
            });
            // When a click event occurs on a feature in the places layer, open a popup at the
            // location of the feature, with description HTML from its properties.
            map.on("click", `relocationPointsLayer-${index}`, (e) => {
              // Copy coordinates array.
              const coordinates = e.features[0].geometry.coordinates.slice();
              const description = e.features[0].properties.description;

              // Ensure that if the map is zoomed out such that multiple
              // copies of the feature are visible, the popup appears
              // over the copy being pointed to.
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }

              new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML(description)
                .addTo(map);
            });

            // Change the cursor to a pointer when the mouse is over the places layer.
            map.on("mouseenter", `relocationPointsLayer-${index}`, () => {
              map.getCanvas().style.cursor = "pointer";
            });

            // Change it back to a pointer when it leaves.
            map.on("mouseleave", `relocationPointsLayer-${index}`, () => {
              map.getCanvas().style.cursor = "";
            });
            return null;
          });

        if (relocalisation) {
          map.getLayer("relocationPointsLayer-0") &&
            relocationPointsData.map((data, index) => {
              map.setLayoutProperty(
                `relocationPointsLayer-${index}`,
                "visibility",
                "visible"
              );
              return null;
            });
        } else {
          map.getLayer("relocationPointsLayer-0") &&
            relocationPointsData.map((data, index) => {
              map.setLayoutProperty(
                `relocationPointsLayer-${index}`,
                "visibility",
                "none"
              );
              return null;
            });
        }
      }
    }

    if (
      !userControllingMap &&
      !!Number(process.env.REACT_APP_USER_MAP_CONTROL) &&
      !geofenceCoordinates.length > 0 &&
      !nogoZonesCoordinates.length > 0 &&
      // robotDetails[robotId].hasOwnProperty("path") &&
      // !robotDetails[robotId].path.length > 0 &&
      robotDetails[robotId].hasOwnProperty("robotCommandList") &&
      !robotDetails[robotId].robotCommandList.length > 0
    ) {
      map &&
        map.flyTo({
          center: [
            slamPosition
              ? robotDetails[robotId].longitudeSlam
              : robotDetails[robotId].longitude,
            slamPosition
              ? robotDetails[robotId].latitudeSlam
              : robotDetails[robotId].latitude,
          ],
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
          zoom: 15,
        });
    }

    // move robot marker
    if (robotMarker) {
      if (
        !!Number(process.env.REACT_APP_HEADING) &&
        (robotDetails[robotId].headingSlam || robotDetails[robotId].heading)
      ) {
        if (!!Number(process.env.REACT_APP_HEADING_ROTATION)) {
          robotMarker
            .setLngLat([
              slamPosition
                ? parseFloat(robotDetails[robotId].longitudeSlam)
                : parseFloat(robotDetails[robotId].longitude),
              slamPosition
                ? parseFloat(robotDetails[robotId].latitudeSlam)
                : parseFloat(robotDetails[robotId].latitude),
            ])
            .setRotation(
              parseFloat(
                robotDetails[robotId].headingSlam
                  ? robotDetails[robotId].headingSlam
                  : robotDetails[robotId].heading
              )
            )
            .setRotationAlignment("map");
        } else {
          robotMarker
            .setLngLat([
              slamPosition
                ? parseFloat(robotDetails[robotId].longitudeSlam)
                : parseFloat(robotDetails[robotId].longitude),
              slamPosition
                ? parseFloat(robotDetails[robotId].latitudeSlam)
                : parseFloat(robotDetails[robotId].latitude),
            ])
            .setRotationAlignment("map");
        }
      } else {
        robotMarker.setLngLat([
          slamPosition
            ? parseFloat(robotDetails[robotId].longitudeSlam)
            : parseFloat(robotDetails[robotId].longitude),
          slamPosition
            ? parseFloat(robotDetails[robotId].latitudeSlam)
            : parseFloat(robotDetails[robotId].latitude),
        ]);
      }
    }

    // Destination Marker on move
    if (
      destinationMarker &&
      robotDetails[robotId].hasOwnProperty("robotCommandList") &&
      robotDetails[robotId].robotCommandList.length > 0 &&
      robotDetails[robotId].robotCommandList.find((x) => x.actionPriority === 1)
    ) {
      let destinationlng = robotDetails[robotId].robotCommandList.find(
        (x) => x.actionPriority === 1
      ).longitude;
      let destinationlat = robotDetails[robotId].robotCommandList.find(
        (x) => x.actionPriority === 1
      ).latitude;

      if (destinationlng && destinationlat) {
        // destinationMarkerDiv.style.visibility = "visible";
        destinationMarker.setLngLat([destinationlng, destinationlat]);

        if (
          !userControllingMap &&
          !!Number(process.env.REACT_APP_USER_MAP_CONTROL)
        ) {
          const mySource = map.getSource("robotPath");
          if (map && mySource) {
            // Create a 'LngLatBounds' with both corners at the first coordinate.
            const bounds = new mapboxgl.LngLatBounds(
              mySource._options.data.geometry.coordinates[0],
              mySource._options.data.geometry.coordinates[0]
            );

            // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
            for (const coord of map &&
              mySource &&
              mySource._options.data.geometry.coordinates) {
              bounds.extend(coord);
            }

            bounds.extend([
              slamPosition
                ? parseFloat(robotDetails[robotId].longitudeSlam)
                : parseFloat(robotDetails[robotId].longitude),
              slamPosition
                ? parseFloat(robotDetails[robotId].latitudeSlam)
                : parseFloat(robotDetails[robotId].latitude),
            ]);

            bounds.extend([destinationlng, destinationlat]);

            destinationMarkerForBound = [destinationlng, destinationlat];

            map.fitBounds(bounds, {
              padding: 100,
              bearing: robotDetails[robotId].headingSlam
                ? parseFloat(robotDetails[robotId].headingSlam)
                : 0,
              duration: 200,
            });
          }
        }
      }
    } else {
      destinationMarker && destinationMarker.setLngLat([0, 0]);
    }

    let offset = 0.00003;

    setNogoZonesCoordinates(
      nogoZones.map((data) => [data.lng, data.lat + offset])
    );

    setGeofenceCoordinates(
      geofenceZone.map((data) => [data.lng, data.lat + offset])
    );

    let polygonArray = [];

    fleetZones.map((polygon, index) => {
      polygonArray.push(polygon.map((data) => [data.lng, data.lat]));
      return polygonArray;
    });

    setPolygonArray(polygonArray);
    if (robotDetails[robotId]) {
      updateRobotPath();
    }
  }, [
    robotDetails[robotId].longitude,
    robotDetails[robotId].latitude,
    robotMarker,
    destinationMarker,
    robotId,
    fleetZones,
    geofenceZone,
    nogoZones,
    robotPathCheck,
    dispatch,
    robotDirections,
    robotDetails,
    robotDetails[robotId],
  ]);

  return (
    <>
      <div className="relative h-full" style={{ width: width }}>
        <div
          className={`sidebar ${
            width === "0%" ? "hidden" : ""
          } w-fit min-h-[35px] h-max bg-[rgba(35,55,75,0.7)] text-white p-[6px_12px] z-[1] absolute top-0 left-0 m-3 rounded-[4px]`}
        >
          <span className="text-sm">
            Ottobot Position [
            {!slamPosition
              ? robotDetails[robotId]?.latitude
              : robotDetails[robotId]?.latitudeSlam}
            ,
            {!slamPosition
              ? robotDetails[robotId]?.longitude
              : robotDetails[robotId]?.longitudeSlam}
            ] | Zoom: {zoom}{" "}
            {robotDetails[robotId]?.slamConfidence &&
              ` | Slam Confidence: ${robotDetails[robotId]?.slamConfidence}`}
          </span>
        </div>
        {userControllingMap &&
          !!Number(process.env.REACT_APP_USER_MAP_CONTROL) && (
            <div
              className={`cursor-pointer ${
                width === "0%" ? "hidden" : "flex"
              } items-center gap-2 sidebar bg-white text-[#00B7D4] p-[6px_12px] z-[1] absolute bottom-0 left-0 m-1 rounded-[4px] h-[30px]`}
              onClick={() => setUserControllingMap(false)}
            >
              <FiNavigation2 strokeWidth={3} />
              <span className="text-xs font-bold uppercase">Re-centre</span>
            </div>
          )}
        <div
          id="map-container-id"
          className="map-container"
          style={{ height: "100%", width: "100%" }}
        />
      </div>
    </>
  );
};
export default memo(MapboxMain);
